//YAML Helpers
import yaml from 'js-yaml';
import { isProxy, toRaw } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';

export const yamlToObject = (string) => yaml.load(string);
export const yamlFirst = (string) => yaml.load(string)[0].trim();

//JSON Helpers
export const cleanUpJSON = (input) => {return JSON.parse(JSON.stringify(input))};

export const objectToArray = (proxyObject) => {return toRaw(proxyObject)};

//Get image from files object by id
export const imageByLongId = function(images, id) {
  if(images) return cleanUpJSON(images.filter( image => (image.id == cleanUp(id)))[0]);
}

export const cleanUp = function(input) {
  return input.replace(/^\s+|\s+$/g, '');
}

//URL to PATH
export const pathName = function(input) {
  let url = new URL(input);
  return url.pathname;
}

export const orientation = function() {
  return window.getComputedStyle(document.body , ':before').content.replace(/"/g, '');
};

//Get alternative language
export const alternativeLanguage = function(i) {
  const linkElementForAlternate = document.head.querySelector('link[rel=alternate]');
  return linkElementForAlternate ? linkElementForAlternate.attributes.hreflang.value : false;
}

//Get image from files object by id
export const imageById = function(images, filename) {
  filename = filename.includes('/') ? filename.split('/').pop() : filename;
  const imageObject = images.filter( image => (image.filename == filename))[0];
  if(images){
    return objectToArray(imageObject);
  } else {
    console.error('No images');
  }
}

//Local images
export const srcImage = ((url) => {
  return new URL(`/src/${url}`, import.meta.url).href;
});

//Local images
export const publicImage = ((url) => {
  return new URL(`/public/${url}`, import.meta.url).href;
});

//Get page object from path
export const pageFromPath = (path, pages) => {
  if (path, pages) {
    var slugs = path.trim().split('/');
    var page = false;
    var children = pages;
    while (slugs.length) {
      var id = slugs.shift();
      page = children.find(o => o.uid === id);
      if (page)
        children = typeof page.children === 'object' ? page.children : [];
    }
    if (page) {
      return page;
    } else {
      console.error('No page!', path, pages);
    }
  } else {
    console.error('No pages! Add a page element with function', path, pages);
  }
}

//Mix lines with page objects
export const featuresAndLines = function(features, featureSizes) {
  const query = (Array.isArray(features) && features.length && features[0] && features[0].uid!=false );
  const sizes = structuredClone(featureSizes);
  if(query){
    var featureLength = parseInt(features.length);
    var returnArray = [];
    let tween = 0;
    let lineObject = {type: 'line', page: false, uid: false};
    if(featureLength < 100){
      for (let index = 0; index < featureLength; index++) {
        const currentSize = Object.keys(sizes)[0];
        const takeFromLine = 1 / sizes[currentSize].featuresPerLine;
        sizes[currentSize].lines = sizes[currentSize].lines - takeFromLine;;
        const lineOrNot = (sizes[currentSize].lines.toFixed(1) % 1) == 0;
        if(sizes[currentSize].lines==0) {
          delete sizes[currentSize];
        };
        features[index].featureSize = currentSize;
        returnArray.push(features[index]);
        if(lineOrNot) {
          returnArray.push(lineObject);
        }
      }
    }
  }
  return returnArray;
}

//Get page objects with list of paths
export const pagesFromPaths = function(list, pages) {
  if(list !='undefined' && pages.length) {
    let self = this;
    let paths = (typeof list == 'array') ? list : Object.values(list);
    let returnArray = [];
    paths.map(function(path){
      let page = pageFromPath(path, pages);
      if(page) returnArray.push(page);
    });
    //console.log( Array.isArray(returnArray));
    return returnArray;
  }
}

class Item {
  constructor(name = 'Black T-shirt', amount = 1000, thumbnail = 'https://picsum.photos/280/320?random=4', description = 'Uniikki tilaustyona valmistettu sormus, jossa osana suunnittelun lahtokohtaa oli uusiokayttaa timantit asiakkaan vanhasta sormuksesta.') {
    return {
      quantity: 1,
      price_data: {
        currency: 'eur',
        unit_amount: amount,
        product_data: {
          name: name,
          images: [thumbnail],
          description: description,
        },
      },
    }
  }
}

export const cartStore = defineStore('cart', {
  persist: true,
  state: () => {
    return {
      api: "/api/checkout",
      options: {
        line_items: [],
        payment_method_types: ['card', 'paypal', 'klarna'],
        mode : 'payment',
        success_url : 'https://local.esternurminen.com/success',
        cancel_url : window.location.href,
      },
      session: {},
      quantity: 0,
    }
  },
  actions: {
    sum(item) {
      return  (this.options.line_items.length) ? this.options.line_items.map(item => item.quantity).reduce((prev, next) => prev + next) : 0;
    },
    removeOne(item) {
      if(item.quantity > 1) {
        item.quantity = item.quantity-1;
        this.quantity = this.quantity-1;
      } else if (item.quantity === 1) {
        this.removeProduct(item);
      }
      this.loadSession();
    },
    removeProduct(item) {
      this.options.line_items = this.options.line_items.filter(x => x.price_data.product_data.name != item.price_data.product_data.name);
    },
    addOne(item) {
      item.quantity = item.quantity+1;
      this.quantity = this.quantity+1;
      this.loadSession();
    },
    addProduct(name = 'Black T-shirt', amount = 1000, thumbnail = 'https://picsum.photos/280/320?random=4', description = 'Uniikki tilaustyona valmistettu sormus, jossa osana suunnittelun lahtokohtaa oli uusiokayttaa timantit asiakkaan vanhasta sormuksesta.') {
      const found = this.options.line_items.find(x => x.price_data.product_data.name === name);
      if(!found) {
        console.log('juu', this.options, found);
        const item = new Item(name, amount, thumbnail, description);
        this.options.line_items.push(item);
        this.quantity = this.quantity+1;
        this.loadSession();
      } else {
        console.log(found);
        this.addOne(found);
      }
    },
    async loadSession() {
      if(this.options.line_items.length > 0) {
        axios.post(this.api, this.options).then((response) => {
          this.session = response.data.session;
        });
      } else {
        //console.info('No line items.');
      }
    }
  }
});

export const navigationStore = defineStore('navigation', {
  //persist: true,
  state: () => {
    return {
      items: [],
    }
  }
});
