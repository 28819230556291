import './index.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { router } from './router.js'
import App from './App.vue'
import VueSmoothScroll from 'vue3-smooth-scroll'
import VueGtag from "vue-gtag"
import PrimeVue from 'primevue/config';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { pageTitle } from 'vue-page-title';

//Constants
const pinia = createPinia()
const app = createApp(App)

//Use
app.use(pinia);
app.use(router);
app.use(VueSmoothScroll);
app.use(PrimeVue);
app.use(VueGtag, {
  config: {
    id: "G-HFJ5SD7YHW",
  },
}, router);
app.use(
  pageTitle({
    suffix: '| Ester Nurminen',
    mixin: true,
  })
);

//Pinia
pinia.use(piniaPluginPersistedstate);

//Mount
app.mount('#app')
