import * as VueRouter from 'vue-router';

import Home from './views/Home.vue'
import Error from './views/Error.vue'
import Default from './views/Default.vue'
import { alternativeLanguage } from './helpers.js';

const multilanguageroutes = [
  { path: '/', redirect: to => { if(document.documentElement.lang) return document.documentElement.lang;}},
  {
    name: 'Language',
    path: '/:language',
    children: [
      { name: 'Home', path: '', component: Home },
      { name: 'Error', path: 'error', component: Default },
      { name: 'Default', path: ':primi', component: Default },
      { name: 'Subpage', path: ':primi/:secondi', component: Default },
      { name: 'Detail', path: ':primi/:secondi/:dessert', component: Default },
    ]
  }
]

const otherLanguage  = alternativeLanguage();
const alternativeRoutes = otherLanguage ? multilanguageroutes : routes;

export const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes: alternativeRoutes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      //setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }
})
