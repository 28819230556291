const For = {}

const language = document.documentElement.lang;

/* BASE FIELDS FOR PAGES */
const Image = {
  id: true,
  filename: true,
  description: 'file.description.kirbytext',
  croppedImage: 'file.croppedImage',
  url: true,
  width: 'file.width()',
  height: 'file.height()',
  ratio: 'file.ratio()',
  srcset: 'file.srcset([250, 450, 800, 1440])',
};

/* GENERAL QUERY STRUCTURE FOR IMAGES */
For.Images = {
  query: 'page.images',
  select: {
    ...Image
  }
};

/* QUERY STRUCTURE FOR DOCUMENTS */
For.Files = {
  query: 'page.files',
  select: {
    id: true,
    filename: true,
    url: true
  }
};

/* BASE FIELDS FOR PAGES */
const Base = {
  title: language ? 'page.content("' + language + '").title' : true,
  url: language ? 'page.url("' + language + '")' : true,
  enurl: 'page.url("en")',
  fiurl: 'page.url("fi")',
  uid: true,
  cover: true,
  description: language ? 'page.content("' + language + '").description' : true,
  shortdescription: language ? 'page.content("' + language + '").shortdescription' : true,
  /* coverFile: 'page.cover.toFile', */
  listed: 'page.isListed',
  productimages: true,
  images: For.Images,
};

/* GENERAL QUERY STRUCTURE FOR CHILDREN*/
For.Children = {
  ...Base,
  type: true,
  children: {
    query: 'page.children',
    select: {
      ...Base,
      type: true,
      date: true,
      released: true,
      runtime: true,
      category: language ? 'page.content("' + language + '").category' : true,
      involvement: language ? 'page.content("' + language + '").involvement' : true,
      text: true
    }
  }
};

For.Default = {
  ...Base,
  price: true,
  specifications: language ? 'page.content("' + language + '").specifications' : true,
  text: language ? 'page.content("' + language + '").text.kirbytext' : 'page.text.kirbytext',
  layout: language ? 'page.content("' + language + '").layout' : true,
}

For.Home = {
  ...Base,
  featured: true,
  more: true,
}


For.Site = {
  title: language ? 'site.content("' + language + '").title' : true,
  footertitle: language ? 'site.content("' + language + '").footertitle' : true,
  info: language ? 'site.content("' + language + '").info' : true,
  follow: language ? 'site.content("' + language + '").follow' : true,
  button: language ? 'site.content("' + language + '").more' : true,
  address: language ? 'site.content("' + language + '").address' : true,
  layout: language ? 'site.content("' + language + '").layout' : true,
  button: language ? 'site.content("' + language + '").button' : true,
  empty: language ? 'site.content("' + language + '").empty' : true,
  checkout: language ? 'site.content("' + language + '").checkout' : true,
  images: {
    query: 'site.images',
    select: {
      ...Image
    }
  }
}

export default {
  For
}
